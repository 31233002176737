import React from 'react';
import { useQueryParams } from 'use-query-params';
import SEO from '@components/SEO';
import Layout from '@components/Layout/LayoutTemplates/CareerDevelopmentLayout';
import ResultsPageSearch from '@components/SearchWithCTA/ResultsPageSearch';
import CategorySlide from '@components/CategorySlide';
import Topbar from '@components/Topbar';
import ArticleCardsViewer from '@components/ArticleCardsViewer';
import Section from '@components/Section';
import NoResultEmptyState from '@components/NoResultEmptyState';
import filterContents from '@helpers/methods/filterContents';

import {
  SORT_ERROR_LOG,
  SORT_LATEST,
  SORT_OLDEST,
  DEFAULT_URL_QUERY,
} from '@helpers/methods/filterContents/constants';

const ResultsPreview = ({ data }) => {
  const {
    allCareerAdvices: contents,
    allCareerAdviceCategories: categories,
    flexSearchOptions,
    searchSuggestions,
  } = data;

  const [filters, setFilters] = useQueryParams(DEFAULT_URL_QUERY);

  const handleCategoryClick = (category) => () => {
    setFilters({ category });
  };

  const handleSortByChange = (sort) => {
    const isSortByLatest = sort === SORT_LATEST;
    const isSortByOldest = sort === SORT_OLDEST;
    const isValueExpected = isSortByLatest || isSortByOldest;
    if (!isValueExpected) {
      console.error(SORT_ERROR_LOG);
      return;
    }
    setFilters({
      sort,
    });
  };

  const handleSearchQuery = (response) => {
    const { results, query } = response;

    const isQueryInvalid = typeof query !== 'string';
    if (isQueryInvalid) return;

    const hasSameQueryAsBefore = filters.query === query;
    if (hasSameQueryAsBefore) return;

    const sanitizeQuery = (queryString) => {
      const isQueryEmptyString = queryString === '';
      return isQueryEmptyString ? undefined : queryString;
    };

    setFilters({
      search: results,
      query: sanitizeQuery(query),
    });
  };

  const visibleContents = filterContents(contents, filters);

  return (
    <Layout className={'career-advice-results-container'}>
      <SEO title="Career Advice Results" description="Results" />
      <ResultsPageSearch
        header="What do you want to learn?"
        searchPlaceholder="Search from our career advice"
        defaultValue={filters.query}
        onQuery={handleSearchQuery}
        flexSearchOptions={flexSearchOptions}
        searchSuggestions={searchSuggestions}
      />
      <Section>
        <CategorySlide
          className="result-preview-category"
          categories={categories}
          currentCategory={filters.category}
          onClick={handleCategoryClick}
        />
      </Section>

      <Section className="career-advice-results-preview-container">
        <Topbar
          defaultValue={filters.sort}
          onChange={handleSortByChange}
          count={visibleContents?.length}
        />
        <ArticleCardsViewer
          EmptyState={<NoResultEmptyState />}
          contents={visibleContents}
          linkRoot={'/career-development/career-advice/'}
        />
      </Section>
    </Layout>
  );
};

export default ResultsPreview;
