import { ALL_CATEGORY, DEFAULT_ID } from '@helpers/constants';
import { useStaticQuery, graphql } from 'gatsby';
import formatCreatedAt from '@helpers/methods/formatCreatedAt';
import useFlexSuggestionIndex from '@helpers/hooks/useFlexSuggestionIndex';
import sortByPublishedDate from '@helpers/methods/sortByPublishedDate';
import getCareerAdviceCardsProps from '@helpers/methods/modifyContents/getCareerAdviceCardsProps';

const DEFAULT_CATEGORY = {
  strapiId: DEFAULT_ID,
  name: ALL_CATEGORY,
};

const Fetcher = ({ children }) => {
  const {
    allStrapiCareerAdvices,
    allStrapiCareerAdviceCategories,
    flexSearchOptions,
    flexSuggestionOptions,
  } = useStaticQuery(
    graphql`
      {
        allStrapiCareerAdvices(
          filter: { content_status: { value: { eq: "2" } } }
        ) {
          nodes {
            creator_id
            created_by {
              username
            }

            category: career_advice_category {
              name
            }

            thumbnail_image {
              source_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
                url
              }
              cropped_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
                url
              }
            }

            thumbnail_image_old: image_thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100)
                }
              }
              url
            }

            id
            slug
            title
            strapiId
            created_at(formatString: "MMM DD, YYYY")
            published_at
            published_date
          }
        }
        allStrapiCareerAdviceCategories {
          nodes {
            name
          }
        }
        flexSearchOptions: localSearchCareerAdvices {
          index
          store
        }
        flexSuggestionOptions: localSearchCareerAdvicesSuggestions {
          index
        }
      }
    `
  );

  const allCareerAdvices = getCareerAdviceCardsProps(
    allStrapiCareerAdvices.nodes
  );

  const allCareerAdviceCategories = [
    DEFAULT_CATEGORY,
    ...allStrapiCareerAdviceCategories.nodes,
  ];

  const suggestedWordsIndex = useFlexSuggestionIndex(
    flexSuggestionOptions?.index
  );
  const suggestedTitlesIndex = allCareerAdvices.map(({ title }) => title);
  const searchSuggestions = [...suggestedWordsIndex, ...suggestedTitlesIndex];

  return children({
    allCareerAdvices,
    allCareerAdviceCategories,
    flexSearchOptions,
    searchSuggestions,
  });
};

export default Fetcher;
